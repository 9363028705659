export const INITIAL_TO_UPDATE_TYPE = {
  "R": "Retired",
  "N": "New",
  "U": "Updated",
  "V": "Vacant",
}

export const DEFAULT_SNACKBAR_TIME = 6000;

export const DEFAULT_SNACKBAR = {
  type: "",
  open: false,
  message: "",
}

export const getError = (e) => {
  try {
    return JSON.parse(e.message).message;
  } catch (e) {
    return "Something went wrong. Try again.";
  }
}
