import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, CircularProgress, TableBody, TableContainer, TableRow, TableCell, Table, Typography } from "@material-ui/core";
import { formatName, isNull, numberToCurrency } from "../../utils/string";
import { useAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";
import { getPixUrl } from "../../utils/link";
import { Alert } from "@material-ui/lab";

function CD() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto",
    },
    tableHead: {
      fontWeight: "bold",
      width: "20%"
    },
    sectionHeader: {
      marginTop: "30px"
    },
    image: {
      width: "90%"
    }
  }));

  const { token } = useAuth();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const { id } = useParams();
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/cd/${id}`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Typography variant="h3">
      {formatName(data.fname, data.mname, data.lname)}
      ({data.Party})
    </Typography>

    <Grid container>
      {!isNull(data.pix) && (
        <Grid item md={4}>
          <img className={classes.image} alt={formatName(data.fname, data.mname, data.lname)} src={getPixUrl(data.pix, "2015CongressionalPhotos")} />
        </Grid>
      )}
      <Grid item md={!isNull(data.pix) ? 8 : 12}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {!isNull(data.Chamer) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Chamber
                </TableCell>
                <TableCell>
                  {data.Chamber}
                </TableCell>
              </TableRow>)}
              {!isNull(data.Hometown) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Hometown
                </TableCell>
                <TableCell>
                  {data.Hometown}
                </TableCell>
              </TableRow>)}
              {!isNull(data.District) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  District
                </TableCell>
                <TableCell>
                  {data.District}
                </TableCell>
              </TableRow>)}
              {!isNull(data.Term) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Term
                </TableCell>
                <TableCell>
                  {data.Term}
                </TableCell>
              </TableRow>)}
              {!isNull(data.DCAddress) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  D.C. Address
                </TableCell>
                <TableCell>
                  {data.DCAddress}<br />{data.DCCity}, {data.DCZip}
                </TableCell>
              </TableRow>)}
              {!isNull(data.EMAIL) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  E-Mail
                </TableCell>
                <TableCell>
                  {data.EMAIL}
                </TableCell>
              </TableRow>)}
              {!isNull(data.Phone) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Phone
                </TableCell>
                <TableCell>
                  {data.DCPhone}
                </TableCell>
              </TableRow>)}
              {!isNull(data.Staff) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Staff
                </TableCell>
                <TableCell>
                  {data.Staff}
                </TableCell>
              </TableRow>)}
              {!isNull(data.Website) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Webpage
                </TableCell>
                <TableCell>
                  {data.Website}
                </TableCell>
              </TableRow>)}
              {!isNull(data["Twitter Handle"]) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Twitter Handle
                </TableCell>
                <TableCell>
                  {data["Twitter Handle"]}
                </TableCell>
              </TableRow>)}
              {!isNull(data["Facebook Handle"]) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Facebook Handle
                </TableCell>
                <TableCell>
                  {data["Facebook Handle"]}
                </TableCell>
              </TableRow>)}
              {!isNull(data["Instagram Handle"]) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Instagram Handle
                </TableCell>
                <TableCell>
                  {data["Instagram Handle"]}
                </TableCell>
              </TableRow>)}
              {!isNull(data.DistrictPhone) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  District Phone
                </TableCell>
                <TableCell>
                  {data.DistrictPhone}
                </TableCell>
              </TableRow>)}
              {!isNull(data.Committees) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Committees
                </TableCell>
                <TableCell>
                  {data.Committees}
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>

    <Typography variant="h3" className={classes.sectionHeader}>
      Personal Information
    </Typography>

    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {!isNull(data.Hometown) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Home Town
            </TableCell>
            <TableCell>
              {data.Hometown}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Born) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              D.O.B.
            </TableCell>
            <TableCell>
              {data.Born}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Occupation) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Occupation
            </TableCell>
            <TableCell>
              {data.Occupation}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Spouse) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Marital Status
            </TableCell>
            <TableCell>
              {data.Spouse}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Children) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Children
            </TableCell>
            <TableCell>
              {data.Children}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Religion) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Religion
            </TableCell>
            <TableCell>
              {data.Religion}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Quote) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Favorite Quote
            </TableCell>
            <TableCell>
              {data.Quote}
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>

    <Typography variant="h3" className={classes.sectionHeader}>
      Professional Information
    </Typography>

    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {!isNull(data.Education) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Education
            </TableCell>
            <TableCell>
              {data.Education}
            </TableCell>
          </TableRow>)}
          {!isNull(data.PoliticalOffices) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Political Offices
            </TableCell>
            <TableCell>
              {data.PoliticalOffices}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Priorities) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Priorities
            </TableCell>
            <TableCell>
              {data.Priorities}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Organizations) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Organizations
            </TableCell>
            <TableCell>
              {data.Organizations}
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>

    {(!isNull(data.BeginningCash) || !isNull(data.TotalReceipts) || !isNull(data.TotalDisbursements) || !isNull(data.CashonHand)) && (
      <Typography variant="h3" className={classes.sectionHeader}>
        2023/2024 Campaign Finances
      </Typography>
    )}
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {!isNull(data.BeginningCash) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Beginning Balance
            </TableCell>
            <TableCell>
              {numberToCurrency(data.BeginningCash)}
            </TableCell>
          </TableRow>)}
          {!isNull(data.TotalReceipts) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Receipts
            </TableCell>
            <TableCell>
              {numberToCurrency(data.TotalReceipts)}
            </TableCell>
          </TableRow>)}
          {!isNull(data.TotalDisbursements) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Expenditures
            </TableCell>
            <TableCell>
              {numberToCurrency(data.TotalDisbursements)}
            </TableCell>
          </TableRow>)}
          {!isNull(data.CashonHand) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Ending Balance
            </TableCell>
            <TableCell>
              {numberToCurrency(data.CashonHand)}
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  </>);
}

export default CD;
