import React from "react";
import { Switch, NavLink, Route } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Hidden, Container, Drawer, Divider, ListSubheader, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { FiberNew as FiberIcon, Search as SearchIcon, Home as HomeIcon, Book as BookIcon, Email as EmailIcon } from "@material-ui/icons";

import Login from "../routes/login";
import MagicLinkLogin from "../routes/login/magic-link";

import News from "../routes/news";
import Search from "../routes/search";
import LatestUpdates from "../routes/latest-updates";
import Vacancies from "../routes/vacancies";

import Districts from "../routes/districts";

import SenateMembership from "../routes/legislature/senate-membership";
import Legislator from "../routes/legislature/legislator";
import HouseMembership from "../routes/legislature/house-membership";
import CongressionalDelegation from "../routes/legislature/congressional-delegation";
import CD from "../routes/legislature/cd";
import LegislatureLeadership from "../routes/legislature/legislative-leadership";
import LegislatureCommittees from "../routes/legislature/legislative-committees";
import Chairs from "../routes/legislature/chairs";
import Committee from "../routes/legislature/committee";

import ExecutiveOffices from "../routes/executive/executive-offices";
import IndependentQuasiPublicAgencies from "../routes/executive/independent-quasi-public-agencies";
import AgencyDirectory from "../routes/executive/agency-directory";
import GovernorsCouncil from "../routes/executive/governors-council";
import Executive from "../routes/executive/executive";
import Division from "../routes/executive/division";
import Agency from "../routes/executive/agency";
import ExecutiveOffice from "../routes/executive/executive-office";

import SupremeJudicialCourt from "../routes/judicial/supreme-judicial-court";
import AppealsCourt from "../routes/judicial/appeals-court";
import TrialCourt from "../routes/judicial/trial-court";
import Judicial from "../routes/judicial/judicial";

import Counties from "../routes/sheriffs/counties";
import CountySheriffs from "../routes/sheriffs/county-sheriffs";
import CountySheriff from "../routes/sheriffs/sheriff";

import DistrictAttorneyCounties from "../routes/district-attorneys/counties";

import PersonnelFile from "../routes/personnel-files/personnel-file";
import ConstitutionalOfficers from "../routes/executive/constitutional-officers";

function Navigation(props) {
  const { handleDrawerToggle, mobileOpen } = props;
  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      height: "100vh",
      position: "relative",
      width: 300,
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    content: {
      "@media print" : {
        height: "auto",
      },
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      height: "90%"
    },
    listSubheader: {
      lineHeight: "30px",
      backgroundColor: "transparent",
      marginBottom: -theme.spacing(1),
    }
  }));

  const classes = useStyles();

  const MAIN_LINKS = [{
    link: "/",
    icon: <HomeIcon />,
    text: "Home"
  }, {
    link: "/search",
    icon: <SearchIcon />,
    text: "Search"
  }, {
    link: "/latest",
    icon: <FiberIcon />,
    text: "Latest Updates"
  }];

  const drawer = isMobile => (<>
    <div className={classes.appBarSpacer} />

    {MAIN_LINKS.map((l, i) => (
      <ListItem key={i} button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={l.link}>
        <ListItemIcon>
          {l.icon}
        </ListItemIcon>
        <ListItemText primary={l.text} />
      </ListItem>
    ))}
    <ListItem button dense onClick={() => window.location.href="http://massachusettsalmanac.com"}>
      <ListItemIcon>
        <BookIcon />
      </ListItemIcon>
      <ListItemText primary="Xtras" />
    </ListItem>
    <Hidden smUp implementation="css">
      <ListItem button dense onClick={() => window.location.href="mailto:cdaniels@statehousenews.com"}>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="Updates or changes?" />
      </ListItem>
    </Hidden>

    <Divider />

    <ListSubheader className={classes.listSubheader}>Legislature</ListSubheader>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/senate-membership"}>
      <ListItemText primary="Senate Membership" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/house-membership"}>
      <ListItemText primary="House Membership" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/legislative-leadership"}>
      <ListItemText primary="Legislative Leadership" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/legislative-committees"}>
      <ListItemText primary="Legislative Committees" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/districts"}>
      <ListItemText primary="District Profiles" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/congressional-delegation"}>
      <ListItemText primary="Congressional Delegation" />
    </ListItem>

    <Divider />

    <ListSubheader className={classes.listSubheader}>Executive</ListSubheader>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/constitutional-officers"}>
      <ListItemText primary="Constitutional Officers" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/executive-offices"}>
      <ListItemText primary="Executive Offices" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/independent-quasi-public-agencies"}>
      <ListItemText primary="Independent/Quasi-Public Agencies" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/agency-directory"}>
      <ListItemText primary="Agency Directory" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/governors-council"}>
      <ListItemText primary="Governor's Council" />
    </ListItem>

    <Divider />

    <ListSubheader className={classes.listSubheader}>Judicial</ListSubheader>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/supreme-judicial-court"}>
      <ListItemText primary="Supreme Judicial Court" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/appeals-court"}>
      <ListItemText primary="Appeals Court" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/trial-court"}>
      <ListItemText primary="Trial Court" />
    </ListItem>

    <Divider />

    <ListSubheader className={classes.listSubheader}>County Officials</ListSubheader>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/district-attorneys"}>
      <ListItemText primary="District Attorneys" />
    </ListItem>
    <ListItem button dense component={NavLink} onClick={() => isMobile ? handleDrawerToggle() : undefined} activeClassName="Mui-selected" to={"/county-sheriffs"}>
      <ListItemText primary="County Sheriffs" />
    </ListItem>
  </>);

  return (
    <>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer(true)}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Box displayPrint="none">
          <Drawer
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer(false)}
          </Drawer>
        </Box>
      </Hidden>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route component={MagicLinkLogin} path="/login/magiclink" />
            <Route component={Login} path="/login" />
            <Route exact={true} path="/">
              <News />
            </Route>
            <Route path="/search">
              <Search />
            </Route>
            <Route path="/latest">
              <LatestUpdates />
            </Route>
            <Route path="/vacancies">
              <Vacancies />
            </Route>

            <Route path="/districts">
              <Districts />
            </Route>

            <Route path="/senate-membership">
              <SenateMembership />
            </Route>
            <Route path="/legislator/:id">
              <Legislator />
            </Route>
            <Route path="/house-membership">
              <HouseMembership />
            </Route>
            <Route path="/congressional-delegation">
              <CongressionalDelegation />
            </Route>
            <Route path="/cd/:id">
              <CD />
            </Route>
            <Route path="/legislative-leadership">
              <LegislatureLeadership />
            </Route>
            <Route path="/legislative-committees">
              <LegislatureCommittees />
            </Route>
            <Route path="/chair/:id">
              <Chairs />
            </Route>
            <Route path="/committee/:id">
              <Committee />
            </Route>

            <Route path="/executive-offices">
              <ExecutiveOffices />
            </Route>
            <Route path="/constitutional-officers">
              <ConstitutionalOfficers />
            </Route>
            <Route path="/independent-quasi-public-agencies">
              <IndependentQuasiPublicAgencies />
            </Route>
            <Route path="/agency-directory">
              <AgencyDirectory />
            </Route>
            <Route path="/governors-council">
              <GovernorsCouncil />
            </Route>
            <Route path="/executive/:id">
              <Executive />
            </Route>
            <Route path="/division/:id">
              <Division />
            </Route>
            <Route path="/agency/:id">
              <Agency />
            </Route>
            <Route path="/executive-office/:parent_id/:id?">
              <ExecutiveOffice />
            </Route>

            <Route path="/supreme-judicial-court">
              <SupremeJudicialCourt />
            </Route>
            <Route path="/appeals-court">
              <AppealsCourt />
            </Route>
            <Route path="/trial-court">
              <TrialCourt />
            </Route>
            <Route path="/judicial/:id">
              <Judicial />
            </Route>

            <Route path="/county-sheriffs/:id">
              <CountySheriffs />
            </Route>
            <Route path="/county-sheriffs">
              <Counties />
            </Route>
            <Route path="/sheriff/:id">
              <CountySheriff />
            </Route>

            <Route path="/district-attorneys">
              <DistrictAttorneyCounties />
            </Route>

            <Route path="/personnel-file/:id">
              <PersonnelFile />
            </Route>
          </Switch>
        </Container>
      </main>
    </>
  );
}

export default Navigation;
