import React, { useEffect, useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Card, CardContent, Box, Divider, Snackbar, Grid, Paper, FormControl, InputLabel, Input, Button, Typography, FormHelperText } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME } from "../../utils/constants";

function MagicLinkLogin() {
  const { setToken, isLoggedIn } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto",
    },
    loginSpacer: {
      marginBottom: "15px",
    }
  }));

  const classes = useStyles();
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);
  const [email, setEmail] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");

    if (token) {
      setToken(token);
    }

    if (isLoggedIn()) {
      history.push("/");
    }
  }, [location.search]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/homepagefreetext`);
      setData(await res.json());
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  const tryLogin = async (event) => {
    event.preventDefault();
    setShowMessage(true);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/magiclink?email=${email}`);

      if (!res.ok) {
        throw new Error((await res.json()).description);
      }
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: e.message});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  return (
    <Grid container spacing={3}>
      {data.primary_content && data.primary_content !== "<p></p>" && (
        <Grid item xs={12}>
          <Card className={classes.equalHeight}>
            <CardContent className="ql-editor" dangerouslySetInnerHTML={{__html: data.primary_content}} />
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
            <Alert severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>

          <Typography variant="h4">Login</Typography>
          <Grid container>
            <Grid item md={5}>
              <form onSubmit={tryLogin}>
                <FormControl className={classes.loginSpacer} fullWidth>
                  <InputLabel htmlFor="username">SHNS Email</InputLabel>
                  <Input required id="username" onChange={e => setEmail(e.target.value)} />
                  <FormHelperText>
                    This should be the email address where you receive SHNS.
                    If you're stuck, email <a href="mailto:subscriptions@statehousenews.com">subscriptions@statehousenews.com</a>
                    &nbsp;or call <a href="tel:7814163674">781-416-3674</a>.
                  </FormHelperText>
                </FormControl>
                <Button type="submit" variant="contained" color="primary" disabled={!email}>
                  Send Magic Link
                </Button>
              </form>
              {!showMessage && (
                <Box mt={2}>
                  <Divider />
                  <Box mt={1}>
                    <Typography variant="subtitle2">or <Link to="/login">login using your AlmanAPP username &amp; password</Link></Typography>
                  </Box>
                </Box>
              )}
              {showMessage && (
                <Box mt={2}>
                  <Typography variant="subtitle2" paragraph>Check your email for a link to login.</Typography>
                  <Typography variant="subtitle2" paragraph>If you don't receive an email within 5 minutes, please confirm you typed your email address correctly, and check your spam folder.</Typography>
                  <Typography variant="subtitle2">If you're still having problems logging in, please contact <a href="mailto:subscriptions@statehousenews.com">subscriptions@statehousenews.com</a>.</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default MagicLinkLogin;
