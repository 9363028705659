import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Paper, CircularProgress, TableContainer, TableRow, TableHead, TableCell, Table, TableBody } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { INITIAL_TO_UPDATE_TYPE, getError } from "../../utils/constants";
import { formatName } from "../../utils/string";
import { getLinkByCategory } from "../../utils/link";
import { useAuth } from "../../contexts/auth";

function LatestUpdates() {
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/latestupdates`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Agency/District</TableCell>
            <TableCell>Division</TableCell>
            <TableCell>Updated</TableCell>
            <TableCell>Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((u, i) => (
            <TableRow hover={true} component={Link} to={getLinkByCategory(u.category, u.id)} key={i}>
              <TableCell>
                {u.status !== "V" && formatName(u.first, u.middle, u.last)}
              </TableCell>
              <TableCell>
                {u.title}
              </TableCell>
              <TableCell>
                {u.section}
              </TableCell>
              <TableCell>
                {u.division}
              </TableCell>
              <TableCell>
                {u.cdate}
              </TableCell>
              <TableCell>
                {INITIAL_TO_UPDATE_TYPE[u.status]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default LatestUpdates;
