import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { TableContainer, TableRow, Paper, TableCell, Table, TableBody, CircularProgress, Typography} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";

function Vacancies() {
  const { token } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/vacancies`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Typography variant="h3">
      Vacancies
    </Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {data.map((v, i) => (
            <TableRow hover={true} component={Link} to={`${v.category}/${v.id}`} key={i}>
              <TableCell>
                {v.title}, {v.section} {v.division ? ", " + v.division : ""}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>);
}

export default Vacancies;
