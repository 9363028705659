import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CircularProgress, Box, Divider, Snackbar, Grid, Paper, FormControl, InputLabel, Input, Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useAuth } from "../../contexts/auth";
import { DEFAULT_SNACKBAR, DEFAULT_SNACKBAR_TIME } from "../../utils/constants";

function Login() {
  const { setToken, isLoggedIn } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto",
    },
    loginSpacer: {
      marginBottom: "15px",
    }
  }));

  const classes = useStyles();
  const [snackbar, setSnackbar] = useState(DEFAULT_SNACKBAR);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (isLoggedIn()) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/homepagefreetext`);
      setData(await res.json());
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  const tryLogin = async (event) => {
    event.preventDefault();

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });

      if (!res.ok) {
        throw new Error((await res.json()).description);
      }

      setToken((await res.json()).access_token);
      return history.push("/");
    } catch (e) {
      console.error(e);
      setSnackbar({type: "error", open: true, message: e.message});
    }
  }

  const handleSnackbarClose = () => {
    setSnackbar(DEFAULT_SNACKBAR);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  return (
    <Grid container spacing={3}>
      {data.primary_content && data.primary_content !== "<p></p>" && (
        <Grid item xs={12}>
          <Card className={classes.equalHeight}>
            <CardContent className="ql-editor" dangerouslySetInnerHTML={{__html: data.primary_content}} />
          </Card>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Snackbar open={snackbar.open} onClose={handleSnackbarClose} autoHideDuration={DEFAULT_SNACKBAR_TIME}>
            <Alert severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>

          <Typography variant="h4">Login</Typography>
          <Grid container>
            <Grid item md={5}>
              <form onSubmit={tryLogin}>
                <FormControl className={classes.loginSpacer} fullWidth>
                  <InputLabel htmlFor="username">Username</InputLabel>
                  <Input required id="username" onChange={e => setUsername(e.target.value)} />
                </FormControl>
                <FormControl className={classes.loginSpacer} fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input required type="password" id="password" onChange={e => setPassword(e.target.value)} />
                </FormControl>
                <Button type="submit" variant="contained" color="primary" disabled={!username || !password}>
                  Login
                </Button>
              </form>
              <Box mt={2}>
                <Divider />
                <Box mt={1}>
                  <Typography variant="subtitle2">or <Link to="/login/magiclink">login using your SHNS email</Link></Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Login;
