import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, CircularProgress, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";

function ExecutiveOffices() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto",
    },
    personList: {
      listStyleType: "none",
    },
    personListItem: {
      marginBottom: "15px",
    }
  }));

  const { token } = useAuth();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/executive`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const resJson = await res.json();

      const details = {}
      const promises = [];
      for (const o of resJson) {
        promises.push(
          fetch(`${process.env.REACT_APP_API_ENDPOINT}/section/${encodeURIComponent(o.lID)}`, {
            headers: {
              "Authorization": `JWT ${token()}`,
            },
          })
        )
      }

      const resolvedPromises = await Promise.all(promises);
      for (const [index, p] of resolvedPromises.entries()) {
        const resJson1 = await p.json();
        details[resJson[index].lID] = resJson1;
      }

      setData({ offices: resJson, ...details });
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <>
        <CircularProgress />
        <Box mt={1}>
          <Typography>Loading executive offices...</Typography>
        </Box>
      </>
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Paper className={classes.paper}>
      {data.offices.length > 0 && (
        <ul className={classes.personList}>
          {data.offices.map((s, i) => (
            <li className={classes.personListItem} key={i}>
              {data[s.lID].length === 0 ?
                <Link to={`/executive-office/${s.lID}`}>{s.lID}</Link>
                : s.lID
              }
              {data[s.lID].length > 0 && (
                <ul classes={classes.personList}>
                  {data[s.lID].sort((a, b) => a.lID > b.lID).map((d, i) => (
                    <li>
                      <Link to={`/executive-office/${encodeURIComponent(s.lID)}/${encodeURIComponent(d.lID)}`}>{d.lID}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
    </Paper>
  );
}

export default ExecutiveOffices;
