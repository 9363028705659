import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, CircularProgress, CardContent, Typography, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";

function News() {
  const { token } = useAuth();

  const useStyles = makeStyles((theme) => ({
    personList: {
      listStyleType: "none",
      paddingLeft: 0,
    },
    personListItem: {
      marginBottom: "5px",
    },
    equalHeight: {
      height: "100%",
    },
    newsAndMovesHeaderContainer: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px 0 ${theme.spacing(1.5)}px !important`,
    },
    customHeader: {
      fontFamily: "Garamond",
      fontSize: "1.5em",
      fontWeight: "bold",
    }
  }));

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/home`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res.ok) {
        throw new Error(await res.text());
      }

      const res1 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/vacancies`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res1.ok) {
        throw new Error(await res1.text());
      }

      const resJson = await res.json()

      setData({ news: resJson.entries, freetext: resJson.freetext, personnel_files: resJson.personnel_files, vacancies: await res1.json() });
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Grid container spacing={3}>
      {data.freetext.primary_content && data.freetext.primary_content !== "<p></p>" && (
        <Grid item xs={12}>
          <Card className={classes.equalHeight}>
            <CardContent className="ql-editor" dangerouslySetInnerHTML={{__html: data.freetext.primary_content}} />
          </Card>
        </Grid>
      )}

      {data.freetext.secondary_content && data.freetext.secondary_content !== "<p></p>" && (
        <Grid item md={4} xs={12}>
          <Card className={classes.equalHeight}>
            <CardContent className="ql-editor" dangerouslySetInnerHTML={{__html: data.freetext.secondary_content}} />
          </Card>
        </Grid>
      )}

      {data.freetext.moves && data.freetext.moves !== "<p></p>" && (
        <Grid item md={4} xs={12}>
          <Card className={classes.equalHeight}>
            <CardContent className="ql-editor" dangerouslySetInnerHTML={{__html: data.freetext.moves}} />
          </Card>
        </Grid>
      )}

      <Grid item md={4} xs={12}>
        <Card className={classes.equalHeight}>
          <CardContent>
            <Typography component="h2" color="textPrimary" className={classes.customHeader}>
              Latest Vacancies
            </Typography>
            {data.vacancies && data.vacancies.length > 0 && (
              <ul className={classes.personList}>
                {data.vacancies.slice(0, 5).map((v, i) => (
                  <li key={i} className={classes.personListItem}>
                    <Link to={`${v.category}/${v.id}`}>{v.title}, {v.section} {v.division ? ", " + v.division : ""}</Link>
                  </li>
                ))}
              </ul>
            )}
            <Button component={Link} to="/vacancies" variant="contained" fullWidth color="primary">See all vacancies</Button>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} spacing={0} className={classes.newsAndMovesHeaderContainer}>
        <Typography component="h2" color="textPrimary" className={classes.customHeader}>
          News and Moves
        </Typography>
      </Grid>

      {data.news.map((d, i) => (
        <Grid key={i} item md={4} xs={12}>
          <Card className={classes.equalHeight}>
            <CardContent>
              <Typography color="primary" gutterBottom>
                {d.Title}
              </Typography>
              <Typography dangerouslySetInnerHTML={{ __html: d.Entry }} component="p" />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default News;
