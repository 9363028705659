import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, CircularProgress, TableBody, TableContainer, TableRow, TableCell, Table, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { formatName, isNull, numberToCurrency, getChamberEnumFromChamber, getWholeCommunityDistrictNames, getPartialCommunityDistrictNames } from "../../utils/string";
import { useAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";
import { getPixUrl } from "../../utils/link";
import InfoMessage from "../../components/InfoMessage";

function Legislator() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto",
    },
    tableHead: {
      fontWeight: "bold",
      width: "20%"
    },
    sectionHeader: {
      marginTop: "30px"
    },
    image: {
      width: "90%"
    }
  }));

  const { token } = useAuth();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [districtData, setDistrictData] = useState(undefined);
  const { id } = useParams();
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/legislative/${id}`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const resJson = await res.json();
      setData(resJson);

      const res1 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/district/legislator/${resJson.legid}?chamber=${getChamberEnumFromChamber(resJson.Chamber)}`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res1.ok) {
        throw new Error(await res1.text());
      }
      setDistrictData(await res1.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Typography variant="h3">
      {data.RNUV === "V" ? "Vacant" : formatName(data.FirstName, data.MiddleName, data.LastName)}
      ({data.party})
    </Typography>
    <Typography variant="h5">
      {!isNull(data.title) && data.title}
    </Typography>

    <Grid container>
      {!isNull(data.pix) && (
        <Grid item md={4}>
          <img className={classes.image} alt={formatName(data.FirstName, data.MiddleName, data.LastName)} src={getPixUrl(data.pix, "2015LegislativePhotos")} />
        </Grid>
      )}
      <Grid item md={!isNull(data.pix) ? 8 : 12}>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {!isNull(data.Chamber) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Chamber
                </TableCell>
                <TableCell>
                  {data.Chamber}
                </TableCell>
              </TableRow>)}
              {!isNull(data.hometown) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Hometown
                </TableCell>
                <TableCell>
                  {data.hometown}
                </TableCell>
              </TableRow>)}
              {!isNull(districtData) && (
                <TableRow>
                  <TableCell className={classes.tableHead}>
                    District
                  </TableCell>
                  <TableCell>
                    {districtData.hasOwnProperty("House District") ?
                      districtData["House District"] :
                      districtData["Senate District"]
                    }:
                    <ul>
                      {getWholeCommunityDistrictNames(districtData).map((s, i) => (
                        <li key={i}>
                          {s}
                        </li>
                      ))}
                      {getPartialCommunityDistrictNames(districtData).map((s, i) => (
                        <li key={i}>
                          {s.name} - {s.part}
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>
              )}
              {!isNull(data.term) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Term
                </TableCell>
                <TableCell>
                  {data.term}
                </TableCell>
              </TableRow>)}
              {!isNull(data.StateHouseAddress) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  State House Address
                </TableCell>
                <TableCell>
                  {data.StateHouseAddress}
                </TableCell>
              </TableRow>)}
              {!isNull(data.email) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  E-Mail
                </TableCell>
                <TableCell>
                  {data.email}
                </TableCell>
              </TableRow>)}
              {!isNull(data.phone) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Phone
                </TableCell>
                <TableCell>
                  {data.phone}
                </TableCell>
              </TableRow>)}
              {!isNull(data.staff) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Staff
                </TableCell>
                <TableCell>
                  {data.staff}
                </TableCell>
              </TableRow>)}
              {!isNull(data.website) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Webpage
                </TableCell>
                <TableCell>
                  {data.website}
                </TableCell>
              </TableRow>)}
              {!isNull(data.twitter) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Twitter Handle
                </TableCell>
                <TableCell>
                  {data.twitter}
                </TableCell>
              </TableRow>)}
              {!isNull(data.facebook) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Facebook Handle
                </TableCell>
                <TableCell>
                  {data.facebook}
                </TableCell>
              </TableRow>)}
              {!isNull(data.instagram) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Instagram Handle
                </TableCell>
                <TableCell>
                  {data.instagram}
                </TableCell>
              </TableRow>)}
              {!isNull(data.sraff) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Staff
                </TableCell>
                <TableCell>
                  {data.staff}
                </TableCell>
              </TableRow>)}
              {!isNull(data.districtPhone) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  District Phone
                </TableCell>
                <TableCell>
                  {data.districtPhone}
                </TableCell>
              </TableRow>)}
              {!isNull(data.districtOffice) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  District Address
                </TableCell>
                <TableCell>
                  {data.districtOffice}
                </TableCell>
              </TableRow>)}
              {!isNull(data.committees) && ( <TableRow>
                <TableCell className={classes.tableHead}>
                  Committees
                </TableCell>
                <TableCell>
                  {data.committees}
                </TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>

    <Typography variant="h3" className={classes.sectionHeader}>
      Personal Information
    </Typography>

    <Grid container>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {!isNull(data.VeteranService) && ( <TableRow>
              <TableCell className={classes.tableHead}>
                Veteran Service
              </TableCell>
              <TableCell>
                {data.VeteranService}
              </TableCell>
            </TableRow>)}
            {!isNull(data.HomeAddress) && ( <TableRow>
              <TableCell className={classes.tableHead}>
                Home Address
              </TableCell>
              <TableCell>
                {data.HomeAddress}
              </TableCell>
            </TableRow>)}
            {!isNull(data.DateOfBirth) && ( <TableRow>
              <TableCell className={classes.tableHead}>
                D.O.B.
              </TableCell>
              <TableCell>
                {data.DateOfBirth}
              </TableCell>
            </TableRow>)}
            {!isNull(data.PlaceOfBirth) && ( <TableRow>
              <TableCell className={classes.tableHead}>
                Place of Birth
              </TableCell>
              <TableCell>
                {data.PlaceOfBirth}
              </TableCell>
            </TableRow>)}
            {!isNull(data.occupation) && ( <TableRow>
              <TableCell className={classes.tableHead}>
                Occupation
              </TableCell>
              <TableCell>
                {data.occupation}
              </TableCell>
            </TableRow>)}
            {!isNull(data.maritalStatus) && ( <TableRow>
              <TableCell className={classes.tableHead}>
                Marital Status
              </TableCell>
              <TableCell>
                {data.maritalStatus}
              </TableCell>
            </TableRow>)}
            {!isNull(data.children) && ( <TableRow>
              <TableCell className={classes.tableHead}>
                Children
              </TableCell>
              <TableCell>
                {data.children}
              </TableCell>
            </TableRow>)}
            {!isNull(data.religion) && ( <TableRow>
              <TableCell className={classes.tableHead}>
                Religion
              </TableCell>
              <TableCell>
                {data.religion}
              </TableCell>
            </TableRow>)}
            {!isNull(data.hobbies) && ( <TableRow>
              <TableCell className={classes.tableHead}>
                Hobbies
              </TableCell>
              <TableCell>
                {data.hobbies}
              </TableCell>
            </TableRow>)}
            {!isNull(data.quote) && ( <TableRow>
              <TableCell className={classes.tableHead}>
                Favorite Quote
              </TableCell>
              <TableCell>
                {data.quote}
              </TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>

    <Typography variant="h3" className={classes.sectionHeader}>
      Professional Information
    </Typography>

    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {!isNull(data.education) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Education
            </TableCell>
            <TableCell>
              {data.education}
            </TableCell>
          </TableRow>)}
          {!isNull(data.PoliticalOffices) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Political Offices
            </TableCell>
            <TableCell>
              {data.PoliticalOffices}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Priorities) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Priorities
            </TableCell>
            <TableCell>
              {data.Priorities}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Organizations) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Organizations
            </TableCell>
            <TableCell>
              {data.Organizations}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Previous) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Previous
            </TableCell>
            <TableCell>
              {data.Previous}
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>

    {(!isNull(data.nprimary) || !isNull(data.ngeneral)) && (
      <Typography variant="h3" className={classes.sectionHeader}>
        2022/2023 Election Results
      </Typography>
    )}
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {!isNull(data.nprimary) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Primary
            </TableCell>
            <TableCell>
              {data.nprimary}
            </TableCell>
          </TableRow>)}
          {!isNull(data.ngeneral) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              General
            </TableCell>
            <TableCell>
              {data.ngeneral}
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>

    <Typography variant="h3" className={classes.sectionHeader}>
      Interest Group Ratings
    </Typography>

    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {!isNull(data.MTA) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Associated Industries of Massachusetts
            </TableCell>
            <TableCell>
              {data.MTA}
            </TableCell>
          </TableRow>)}
          {!isNull(data.CLT) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Citizens for Limited Taxation
            </TableCell>
            <TableCell>
              {data.CLT}
            </TableCell>
          </TableRow>)}
          {!isNull(data.ELM) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Environmental League of Massachusetts
            </TableCell>
            <TableCell>
              {data.ELM}
            </TableCell>
          </TableRow>)}
          {!isNull(data.GOAL) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Gun Owners Action League
            </TableCell>
            <TableCell>
              {data.GOAL}
            </TableCell>
          </TableRow>)}
          {!isNull(data.MAS) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Massachusetts Audubon Society
            </TableCell>
            <TableCell>
              {data.MAS}
            </TableCell>
          </TableRow>)}
          {!isNull(data.MFA) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Massachusetts Fiscal Alliance
            </TableCell>
            <TableCell>
              {data.MFA}
            </TableCell>
          </TableRow>)}
          {!isNull(data.MASSPIRG) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              MASSPIRG
            </TableCell>
            <TableCell>
              {data.MASSPIRG}
            </TableCell>
          </TableRow>)}
          {!isNull(data.NFIB) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              National Federation of Independent Businesses
            </TableCell>
            <TableCell>
              {data.NFIB}
            </TableCell>
          </TableRow>)}
          {!isNull(data.PM) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Progressive Massachusetts
            </TableCell>
            <TableCell>
              {data.PM}
            </TableCell>
          </TableRow>)}
          {!isNull(data.PP) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Planned Parenthood
            </TableCell>
            <TableCell>
              {data.PP}
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>

    {(!isNull(data.B_Balance) || !isNull(data.Receipts) || !isNull(data.Spending) || !isNull(data.E_Balance)) && (
      <Typography variant="h3" className={classes.sectionHeader}>
        2023/2024 Campaign Finances
      </Typography>
    )}
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {!isNull(data.B_Balance) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Beginning Balance
            </TableCell>
            <TableCell>
              {numberToCurrency(data.B_Balance)}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Receipts) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Receipts
            </TableCell>
            <TableCell>
              {numberToCurrency(data.Receipts)}
            </TableCell>
          </TableRow>)}
          {!isNull(data.Spending) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Expenditures
            </TableCell>
            <TableCell>
              {numberToCurrency(data.Spending)}
            </TableCell>
          </TableRow>)}
          {!isNull(data.E_Balance) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Ending Balance
            </TableCell>
            <TableCell>
              {numberToCurrency(data.E_Balance)}
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>

    {/* <Typography variant="h3" className={classes.sectionHeader}>
      Key Votes
    </Typography>

    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {!isNull(data.kv1) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Key Vote 1
            </TableCell>
            <TableCell>
              {data.kv1}
            </TableCell>
          </TableRow>)}
          {!isNull(data.kv2) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Key Vote 2
            </TableCell>
            <TableCell>
              {data.kv2}
            </TableCell>
          </TableRow>)}
          {!isNull(data.kv3) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Key Vote 3
            </TableCell>
            <TableCell>
              {data.kv3}
            </TableCell>
          </TableRow>)}
          {!isNull(data.kv4) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Key Vote 4
            </TableCell>
            <TableCell>
              {data.kv4}
            </TableCell>
          </TableRow>)}
          {!isNull(data.kv5) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Key Vote 5
            </TableCell>
            <TableCell>
              {data.kv5}
            </TableCell>
          </TableRow>)}
          {!isNull(data.kv6) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Key Vote 6
            </TableCell>
            <TableCell>
              {data.kv6}
            </TableCell>
          </TableRow>)}
          {!isNull(data.kv7) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Key Vote 7
            </TableCell>
            <TableCell>
              {data.kv7}
            </TableCell>
          </TableRow>)}
          {!isNull(data.kv8) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Key Vote 8
            </TableCell>
            <TableCell>
              {data.kv8}
            </TableCell>
          </TableRow>)}
          {!isNull(data.kv9) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Key Vote 9
            </TableCell>
            <TableCell>
              {data.kv9}
            </TableCell>
          </TableRow>)}
          {!isNull(data.kv10) && ( <TableRow>
            <TableCell className={classes.tableHead}>
              Key Vote 10
            </TableCell>
            <TableCell>
              {data.kv10}
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer> */}
  </>);
}

export default Legislator;
