import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import { Paper, CircularProgress, } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";

function PersonnelFile() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const { token } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const { id } = useParams();
  const [error, setError] = useState(undefined);

  const specialStyles = ".TopScroll { width: 1000px; height:37px; border: 1px solid #bed5cd; overflow-x: scroll; overflow-y: hidden; white-space: nowrap; } .style8 { font-size: x-small; font-family: Arial, Helvetica, sans-serif; } .style9 { font-family: Arial, Helvetica, sans-serif; } .style11 { font-size: small; font-family: Arial, Helvetica, sans-serif; } .mpa_pf_display > p { font-size: small; font-family: Arial, Helvetica, sans-serif; line-height: 1.6em; font-weight: bold; margin: 0em auto -0.8em; color: rgb(51, 51, 51); font-style: normal; font-variant: normal; letter-spacing: normal; orphans: auto; text-align: justify; text-indent: 0px; text-transform: none; white-space: normal; widows: 1; word-spacing: 0px; -webkit-text-stroke-width: 0px; padding-bottom: 2ex; } .mpa_pf_display > p ~ p { font-size: x-small; font-family: Arial, Helvetica, sans-serif; line-height: 1.6em; color: rgb(51, 51, 51); font-style: normal; font-variant: normal; font-weight: normal; letter-spacing: normal; orphans: auto; text-align: justify; text-indent: 0px; text-transform: none; white-space: normal; widows: 1; word-spacing: 0px; -webkit-text-stroke-width: 0px; padding-bottom: 3ex; }";

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/personnelfile/${id}`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (
    <Paper className={classes.paper}>
      <style type="text/css">
        { specialStyles }
      </style>
      <div className="mpa_pf_display" dangerouslySetInnerHTML={{ __html: data.data[0].body }} />
    </Paper>
  );
}

export default PersonnelFile;

