import React, { createContext, useContext } from "react";

import { parseJwt } from "../utils/jwt";

const AuthContext = createContext();

const TOKEN_LOCAL_STORAGE_KEY = "TOKEN";

export const AuthProvider = ({ children }) => {
  const setToken = (token) => {
    localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, token);
  }

  const token = () => {
    return localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
  }

  const isLoggedIn = () => {
    const jwt = token();
    if (jwt === null) {
      return false;
    } else {
      return Date.now() < (parseJwt(jwt).exp * 1000);
    }
  }

  return (
    <AuthContext.Provider value={{ token, setToken, isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext);
