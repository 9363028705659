import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import { Typography, Paper, CircularProgress, TableContainer, TableRow, TableCell, Table, TableBody } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { formatName, isNull } from "../../utils/string";
import { useAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";

function Chairs() {
  const { token } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const { id } = useParams();
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/committeechairs/${id}`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      const resJson = await res.json();
      if (resJson.length === 0) {
        history.push("/legislative-leadership");
      }

      setData(resJson);
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Typography variant="h3">
      {data.length > 0 && (
        data[0].CommitteeName
      )}
    </Typography>
    <Typography variant="h5">
      {data.length > 0 && (
        !isNull(data[0].RoomNumber) && `State House Room Number ${data[0].RoomNumber},`
      )}
      {data.length > 0 && (
        !isNull(data[0].PhoneNumber) && `Phone Number: ${data[0].PhoneNumber}`
      )}
    </Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {data.map((u, i) => (
            !isNull(u.LastName) && (
              <TableRow hover={true} component={Link} to={`/legislator/${u.ID}`} key={i}>
                <TableCell>
                  {formatName(u.FirstName, u.MiddleName, u.LastName)}
                </TableCell>
                <TableCell>
                  {u.Title}
                </TableCell>
              </TableRow>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>);
}

export default Chairs;
