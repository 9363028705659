import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, CircularProgress, Typography, TextField, Grid } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";

import { useAuth } from "../../contexts/auth";
import { isNull, formatName, getWholeCommunityDistrictNames, getPartialCommunityDistrictNames } from "../../utils/string";
import { getError } from "../../utils/constants";

function Districts() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto",
    },
  }));

  const { token } = useAuth();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isLegislatorSubmitLoading, setIsLegislatorSubmitLoading] = useState(false);
  const [isCommunitySubmitLoading, setIsCommunitySubmitLoading] = useState(false);
  const [legislatorData, setLegislatorData] = useState(undefined);
  const [communityData, setCommunityData] = useState(undefined);
  const [communities, setCommunities] = useState(undefined);
  const [senatePeople, setSenatePeople] = useState(undefined);
  const [housePeople, setHousePeople] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const isHoR = (chamber) => {
    return chamber === 'House of Representatives';
  }

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/house`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res.ok) {
        throw new Error(await res.text());
      }

      const res2 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/senate`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res2.ok) {
        throw new Error(await res2.text());
      }

      const res1 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/district/communities`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res1.ok) {
        throw new Error(await res1.text());
      }

      setHousePeople(await res.json());
      setSenatePeople(await res2.json());
      setCommunities(await res1.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  const handleLegislatorSubmit = async (data) => {
    try {
      setLegislatorData(undefined);

      if (isNull(data)) {
        return;
      }

      setIsLegislatorSubmitLoading(true);
      const chamber = isHoR(data.Chamber) ? 'house' : 'senate'
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/district/legislator/${data.ID}?${new URLSearchParams({chamber})}`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setLegislatorData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLegislatorSubmitLoading(false);
    }
  }

  const handleCommunitySubmit = async (data) => {
    try {
      setCommunityData(undefined);

      if (isNull(data)) {
        return;
      }

      setIsCommunitySubmitLoading(true);
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/district/community/${data}`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setCommunityData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsCommunitySubmitLoading(false);
    }
  }

  const getCommunityDisplay = (data, type) => {
    return (<>
      {data.length === 1 ? type : `${type}s`}:&nbsp;
      {data.map((row, i) => {
        const wholeNames = getWholeCommunityDistrictNames(row).join('; ');
        const partialNames = getPartialCommunityDistrictNames(row);
        return (
          <Box key={i}>
            <Link to={`/legislator/${row.Occupant_ID}`}>{row.Occupant}</Link>&nbsp;-&nbsp;
            {wholeNames}{wholeNames && partialNames.length > 0 ? '; ' : ''}
            {partialNames.map((p, i) => (
              <React.Fragment key={i}>
                {((p.length !== i + 1) && i !== 0) && '; '}
                {p.name} {p.part}
              </React.Fragment>
            ))}
          </Box>
        )})}
    </>);
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Typography variant="h3">
      District Profiles
    </Typography>

    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <Paper className={classes.paper}>
          <Typography paragraph>
            Find Districts by Legislator
          </Typography>
          <Autocomplete options={senatePeople} getOptionLabel={(o) => formatName(o.FirstName, o.MiddleName, o.LastName)} renderInput={(params) => <TextField {...params} label="Senate" variant="outlined" />} onChange={(_, o) => handleLegislatorSubmit(o)} />
          <Box mt={1}>
            <Autocomplete options={housePeople} getOptionLabel={(o) => formatName(o.FirstName, o.MiddleName, o.LastName)} renderInput={(params) => <TextField {...params} label="House" variant="outlined" />} onChange={(_, o) => handleLegislatorSubmit(o)} />
          </Box>

          <Box mt={3}>
            {isLegislatorSubmitLoading && (
              <CircularProgress />
            )}

            {legislatorData !== undefined && (<>
              <Typography paragraph>
                <Link to={`/legislator/${legislatorData.Occupant_ID}`}>
                  View {legislatorData.Occupant}'s profile
                </Link>
              </Typography>
              <Typography variant="h5">
                {legislatorData.hasOwnProperty("House District") ?
                  legislatorData["House District"] :
                  legislatorData["Senate District"]
                }:
              </Typography>
              <ul>
                {getWholeCommunityDistrictNames(legislatorData).map((s, i) => (
                  <li key={i}>
                    {s}
                  </li>
                ))}
                {getPartialCommunityDistrictNames(legislatorData).map((s, i) => (
                  <li key={i}>
                    {s.name} - {s.part}
                  </li>
                ))}
              </ul>
            </>)}
          </Box>
        </Paper>
      </Grid>
      <Grid item md={6} xs={12}>
        <Paper className={classes.paper}>
          <Typography paragraph>
            Find Legislators by Community
          </Typography>
          <Autocomplete options={communities} renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />} onChange={(_, o) => handleCommunitySubmit(o)} />

          <Box mt={3}>
            {isCommunitySubmitLoading && (
              <CircularProgress />
            )}

            <Box>
              {communityData !== undefined && communityData.senators !== undefined && communityData.senators.length > 0 && (
                <>
                  {getCommunityDisplay(communityData.senators, 'Senator')}
                </>
              )}
            </Box>
            <Box mt={1}>
              {communityData !== undefined && communityData.representatives !== undefined && communityData.representatives.length > 0 && (
                <>
                  {getCommunityDisplay(communityData.representatives, 'Representative')}
                </>
              )}
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  </>);
}

export default Districts;
