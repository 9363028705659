import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper, CircularProgress, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { isNull } from "../../utils/string";
import { getError } from "../../utils/constants";
import { useAuth } from "../../contexts/auth";

function Agency() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
    },
    divider: {
      marginTop: "15px",
      marginBottom: "15px",
    }
  }));

  const { token } = useAuth();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const { id } = useParams();
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/agency/${id}`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Typography variant="h3">
      {data.AgencyName}
    </Typography>
    <Paper className={classes.paper}>
      {!isNull(data.AgencyInfo) && (
        <Typography>
          {data.AgencyInfo}
        </Typography>
      )}

      <Divider className={classes.divider} />

      {!isNull(data.Address1) && (
        <Typography>{data.Address1}</Typography>
      )}
      {!isNull(data.Address2) && (
        <Typography>{data.Address2}</Typography>
      )}
      {!isNull(data.CityStateZip) && (
        <Typography>{data.CityStateZip}</Typography>
      )}
      {!isNull(data.MapInfo) && (
        <Typography>
          <a rel="noreferrer" target="_blank" href={data.MapInfo}>Map</a>
        </Typography>
      )}
      {!isNull(data.Tel) && (
        <Typography>Tel: {data.Tel}</Typography>
      )}
      {!isNull(data.Fax) && (
        <Typography>FAX: {data.Fax}</Typography>
      )}
      {!isNull(data.EMail) && (
        <Typography>
          <a href={`mailto:${data.EMail}`}>{data.EMail}</a>
        </Typography>
      )}
      {!isNull(data.Web) && (
        <Typography>
          <a rel="noreferrer" target="_blank" href={data.Web}>{data.Web}</a>
        </Typography>
      )}
      {!isNull(data.twitter) && (
        <Typography>{data.twitter}</Typography>
      )}
      <Typography>
        <a rel="noreferrer" target="_blank" href="https://budget.digital.mass.gov/bb/gaa/fy2020/app_20/ga_20/hcdefault.htm">State Government Org Chart</a>
      </Typography>
    </Paper>

  </>);
}

export default Agency;
