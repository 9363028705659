import React, { useState } from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography, TextField, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, CircularProgress } from "@material-ui/core";

import { formatName } from "../../utils/string";
import { getLinkByCategory } from "../../utils/link";
import { useAuth } from "../../contexts/auth";

function Search() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      height: "100%",
      overflow: "auto",
    },
  }));

  const { token } = useAuth();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = (e) => {
    const value = e.target.value;

    if (value === "") {
      setData([]);
    }

    if(e.key === "Enter") {
      callSearch(value);
    }
  }

  const callSearch = async (searchTerm) => {
    setIsLoading(true);

    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/quick?search_term=${searchTerm}`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });

      if (!res.ok) {
        throw new Error(await res.text());
      }

      setData(await res.json());
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <Paper className={classes.paper}>
          <Typography component="h2" noWrap>
            Search for person
          </Typography>
          <TextField fullWidth onKeyDown={handleSearch} label="Search by name" />
        </Paper>
      </Grid>
      <Grid item md={6} xs={12}>
        <Paper className={classes.paper}>
          <Typography component="h2" noWrap>
            Search for agency/department
          </Typography>
          <TextField fullWidth onKeyDown={handleSearch} label="Search by name" />
        </Paper>
      </Grid>

      {isLoading && (
        <CircularProgress />
      )}

      {data.length > 0 && (
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h2" noWrap>
              Search Results
            </Typography>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Agency/District</TableCell>
                    <TableCell>Division</TableCell>
                    <TableCell>Staff</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((u, i) => (
                    <TableRow hover={true} component={Link} to={getLinkByCategory(u.category, u.id)} key={i}>
                      <TableCell>
                        {u.status !== "V" && formatName(u.first, u.middle, u.last)}
                      </TableCell>
                      <TableCell>
                        {u.title}
                      </TableCell>
                      <TableCell>
                        {u.section}
                      </TableCell>
                      <TableCell>
                        {u.division}
                      </TableCell>
                      <TableCell>
                        {u.staff}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      )}

      {data.length === 0 && (
        <Typography>No results found.</Typography>
      )}
    </Grid>
  );
}

export default Search;
