import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, Grid, Hidden, Button, Box } from "@material-ui/core";
import { Menu as MenuIcon, Email as EmailIcon } from "@material-ui/icons";

function Header(props) {
  const { handleDrawerToggle } = props;
  const useStyles = makeStyles((theme) => ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    logo: {
      display: "block",
      height: "50px",
    },
    emailIcon: {
      marginRight: "5px",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  }));

  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={1} color="white">
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Box width="50%">
          <Link to="/">
            <img className={classes.logo} src="/logo.png" alt="AlmanAPP" />
          </Link>
        </Box>
        <Grid container justifyContent="flex-end">
          <Hidden xsDown implementation="css">
            <Grid container alignItems="center">
              <Button component="a" color="primary" href="mailto:cdaniels@statehousenews.com">
                <EmailIcon className={classes.emailIcon} />
                Updates or changes?
              </Button>
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
