export const getLinkByCategory = (category, id) => {
  let baseUrl = "/";

  switch (category) {
    case "executive":
      baseUrl += "executive";
      break;
    case "usdelegation":
      baseUrl += "cd";
      break;
    case "judicial":
      baseUrl += "judicial";
      break;
    case "sheriff":
      baseUrl += "sheriff";
      break;
    default:
      baseUrl += "legislator";
  }

  return `${baseUrl}/${id}`;
}

export const getPixUrl = (pix, optionalPath) => {
  if (pix.startsWith(process.env.REACT_APP_S3_IMAGES_BUCKET)) {
    return pix;
  }

  return `${process.env.REACT_APP_S3_IMAGES_BUCKET}/${optionalPath}/${pix}`;
}
