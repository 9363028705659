export const formatName = (firstName, middleName, lastName, title) => {
  const lastNameFirstName = [];
  if (!isNull(lastName)) {
    lastNameFirstName.push(lastName);
  }
  if (!isNull(firstName)) {
    lastNameFirstName.push(firstName);
  }

  return `${lastNameFirstName.join(", ")} ${!isNull(middleName) ? middleName : ""} ${!isNull(title) ? " - " + title: ""}`;
}

export const isNull = (str) => {
  return str === null || str === undefined || str.toString().toUpperCase() === "NULL" || str === "";
}

export const numberToCurrency = (currency) => {
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(currency.toString().replace(/,/g, ''));
}

export const capitalize = ([firstLetter, ...restOfWord]) => {
  return firstLetter.toUpperCase() + restOfWord.join("");
}

export const getWholeCommunityDistrictNames = (data) => {
  const keys = Object.keys(data).filter(k => k.startsWith("Whole-Community-Name_"));
  return keys.map(k => data[k]).filter(d => !isNull(d)).sort();
}

export const getPartialCommunityDistrictNames = (data) => {
  const keys = Object.keys(data).filter(k => k.startsWith("Partial-Community-Name_") && !isNull(data[k])).sort();
  const result = [];

  for (const k of keys) {
    const name = data[k];
    const part = data[k.replace("Name", "Part")];
    result.push({ name, part });
  }

  return result;
}

export const getChamberEnumFromChamber = (data) => {
  if (data === 'House of Representatives') {
    return 'house';
  } else if (data === 'Senate') {
    return 'senate';
  }
}
