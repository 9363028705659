import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, CircularProgress, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { formatName } from "../../utils/string";
import { useAuth } from "../../contexts/auth";
import { getError } from "../../utils/constants";

function LegislativeLeadership() {
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(2),
      marginBottom: "30px",
    },
    personList: {
      listStyleType: "none",
    },
    personListItem: {
      marginBottom: "15px",
    },
    equalHeight: {
      height: "100%"
    }
  }));

  const { token } = useAuth();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/houseleadership`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res.ok) {
        throw new Error(await res.text());
      }

      const res1 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/senateleadership`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res1.ok) {
        throw new Error(await res1.text());
      }

      const res2 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/housecommittees`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res2.ok) {
        throw new Error(await res2.text());
      }

      const res3 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/senatecommittees`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res3.ok) {
        throw new Error(await res3.text());
      }

      const res4 = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/jointcommittees`, {
        headers: {
          "Authorization": `JWT ${token()}`,
        },
      });
      if (!res4.ok) {
        throw new Error(await res4.text());
      }

      setData({
        house: await res.json(),
        senate: await res1.json(),
        houseCommittees: await res2.json(),
        senateCommittees: await res3.json(),
        jointCommittees: await res4.json()
      });
    } catch (e) {
      console.error(e);
      setError({type: "error", open: true, message: getError(e)});
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <CircularProgress />
    );
  }

  if (error) {
    return (
      <Alert severity={error.type}>
        {error.message}
      </Alert>
    );
  }

  return (<>
    <Paper className={classes.paper}>
      <Typography component="h2" noWrap>
        Senate Leadership
      </Typography>
      {data.senate.length > 0 && (
        <ul className={classes.personList}>
          {data.senate.map((s, i) => (
            <li className={classes.personListItem} key={i}>
              <Link to={`legislator/${s.ID}`}>{formatName(s.FirstName, s.MiddleName, s.LastName, s.title)}</Link>
            </li>
          ))}
        </ul>
      )}
    </Paper>
    <Paper className={classes.paper}>
      <Typography component="h2" noWrap>
        House Leadership
      </Typography>
      {data.house.length > 0 && (
        <ul className={classes.personList}>
          {data.house.map((s, i) => (
            <li className={classes.personListItem} key={i}>
              <Link to={`legislator/${s.ID}`}>{formatName(s.FirstName, s.MiddleName, s.LastName, s.title)}</Link>
            </li>
          ))}
        </ul>
      )}
    </Paper>
    <Grid container spacing={3}>
      <Grid item md={4}>
        <Paper className={classes.paper + " " + classes.equalHeight}>
          <Typography component="h2" noWrap>
            House Chairs
          </Typography>
          {data.houseCommittees.length > 0 && (
            <ul className={classes.personList}>
              {data.houseCommittees.map((s, i) => (
                <li className={classes.personListItem} key={i}>
                  <Link to={`chair/${s.lID}`}>{s.Name}</Link>
                </li>
              ))}
            </ul>
          )}
        </Paper>
      </Grid>
      <Grid item md={4}>
        <Paper className={classes.paper + " " + classes.equalHeight}>
          <Typography component="h2" noWrap>
            Senate Chairs
          </Typography>
          {data.senateCommittees.length > 0 && (
            <ul className={classes.personList}>
              {data.senateCommittees.map((s, i) => (
                <li className={classes.personListItem} key={i}>
                  <Link to={`chair/${s.lID}`}>{s.Name}</Link>
                </li>
              ))}
            </ul>
          )}
        </Paper>
      </Grid>
      <Grid item md={4}>
        <Paper className={classes.paper + " " + classes.equalHeight}>
          <Typography component="h2" noWrap>
            Joint Chairs
          </Typography>
          {data.jointCommittees.length > 0 && (
            <ul className={classes.personList}>
              {data.jointCommittees.map((s, i) => (
                <li className={classes.personListItem} key={i}>
                  <Link to={`chair/${s.lID}`}>{s.Name}</Link>
                </li>
              ))}
            </ul>
          )}
        </Paper>
      </Grid>
    </Grid>
  </>);
}

export default LegislativeLeadership;
